<template>
	<div>
		<!-- Modal Atualização -->
		<div class="modal fade" id="updateModal" tabindex="-1" role="dialog" aria-labelledby="updateModalLabel"
			aria-hidden="true">
			<div class="modal-dialog" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title font-weight-bold" id="updateModalLabel">Deseja realmente realizar esta operação?</h5>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-footer">
						<button type="button" data-dismiss="modal" class="btn btn-outline-secondary">Não</button>
						<button @click="updateCompany()" type="button" class="btn btn-primary" data-dismiss="modal">Sim</button>
					</div>
				</div>
			</div>
		</div>
		<!-- Modal Deletar Integração-->
		<div class="modal fade" id="deleteModal" tabindex="-1" role="dialog" aria-labelledby="deleteModalLabel"
			aria-hidden="true">
			<div class="modal-dialog" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title font-weight-bold" id="updateModalLabel">Deseja realmente realizar esta operação?</h5>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-footer">
						<button type="button" data-dismiss="modal" class="btn btn-outline-secondary">Não</button>
						<button @click="confirmDeleteIntegration()" type="button" class="btn btn-danger"
							data-dismiss="modal">Sim</button>
					</div>
				</div>
			</div>
		</div>
		<!-- Modal inadimplente -->
		<div class="modal fade" id="modalDefaulting" tabindex="-1" role="dialog" aria-labelledby="modalDefaultingLabel"
			aria-hidden="true">
			<div class="modal-dialog" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title font-weight-bold" id="modalDefaultingLabel">Contato</h5>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">
						<!-- se nao existir dados -->
						<div v-if="!company.focal_contact || !company.phone">
							<p class="m-0 p-0">Contato focal não foi cadastrado ainda.</p>

							<router-link :to="{ name: 'info_company', params: { id: company._id, } }">
								<a href="" data-dismiss="modal">Cadastrar informações de contato</a>
							</router-link>
						</div>

						<!-- se existir dados -->
						<div v-else>
							<p>Dados do contato focal:</p>
							<ul>
								<li>{{ company.focal_contact }}</li>
								<li>{{ company.phone }}</li>
								<li><a :href="`mailto:${company.email}`">{{ company.email }}</a></li>
							</ul>
							<p>Caso a informação que você deseja não esteja aparecendo, você poderá
								<router-link :to="{ name: 'info_company', params: { id: company._id, } }">
									<a href="" data-dismiss="modal">atualizar ou incluir dados para o contato focal.</a>
								</router-link>
							</p>
						</div>
						<!-- <p class="font-weight-bold">Você confirma a finalização da etapa?</p> -->
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-outline-secondary" data-dismiss="modal">Fechar</button>
						<!-- <button type="button" data-dismiss="modal" class="btn btn-primary">Finalizar</button> -->
					</div>
				</div>
			</div>
		</div>

		<ModalSubmitForwardPhrase :isLoading="modalForwardPhraseIsLoading" :phrasesDocument="forwardPhraseDocument"
			@save="handleUpdateForwardPhrases" />


		<div class="content-wrapper px-3">
			<TitlePage :title="titlePage" :iconClass="iconClass"
				:routeButton="$route.params.rd_billing ? 'companies_rd_billing' : 'companies'" />

			<div class="div-hashs btn-group-vertical ">
				<div class="btn-group-vertical ml-1" v-if="showNavItems">
					<a href="#TD" class="btn btn-sm" data-toggle="tooltip" data-placement="right"
						title="Tipo de Conta e Dias">TD</a>
					<a href="#NRC" class="btn btn-sm" data-toggle="tooltip" data-placement="right"
						title="Nome, Razão Social, CNPJ">NRC</a>
					<a href="#EA" class="btn btn-sm" data-toggle="tooltip" data-placement="right"
						title="Encerramento Automático">EA</a>
					<a href="#PRT" class="btn btn-sm" data-toggle="tooltip" data-placement="right" title="Protocolos">PRT</a>
					<a href="#OIT" class="btn btn-sm" data-toggle="tooltip" data-placement="right"
						title="Ocultar Inicio e Término">OIT</a>
					<a href="#OPF" class="btn btn-sm" data-toggle="tooltip" data-placement="right"
						title="Ocultar Posição em Fila">OPF</a>
					<a href="#OT" class="btn btn-sm" data-toggle="tooltip" data-placement="right"
						title="Ocultar Transferência">OT</a>
					<a href="#TAB" class="btn btn-sm" data-toggle="tooltip" data-placement="right" title="Tabulação">TAB</a>
					<a href="#SET" class="btn btn-sm" data-toggle="tooltip" data-placement="right" title="Setores">SET</a>
					<a href="#LTI" class="btn btn-sm" data-toggle="tooltip" data-placement="right"
						title="Limitar Tentativas de Interações">LTI</a>
					<a href="#ES" class="btn btn-sm" data-toggle="tooltip" data-placement="right" title="Expediente Semanal">ES</a>
					<a href="#DE" class="btn btn-sm" data-toggle="tooltip" data-placement="right" title="Dados Endereço">DE</a>
					<a href="#FSH" class="btn btn-sm" data-toggle="tooltip" data-placement="right" title="Fuso Horário">FSH</a>
					<a href="#INT" class="btn btn-sm" data-toggle="tooltip" data-placement="right" title="Integrações">INT</a>
					<a href="#MOD" class="btn btn-sm" data-toggle="tooltip" data-placement="right" title="Módulos">MOD</a>
					<a href="#PERM" class="btn btn-sm" data-toggle="tooltip" data-placement="right" title="Permissões">PERM</a>
				</div>
				<button style="color: #0007" class="btn" data-toggle="tooltip" data-placement="bottom"
					:title="!showNavItems ? 'Mostrar Navegação' : 'Ocultar Navegação'" @click="showNavItems = !showNavItems">
					<i :class="showNavItems ? 'fa fa-eye' : 'fa fa-eye-slash'"></i>
				</button>
			</div>

			<section class="content" v-if="companyExists">

				<!-- info gerais -->
				<div>
					<div v-if="company.defaulting" class="alert alert-danger" role="alert">
						<h3>Atenção</h3>
						<p>Esta empresa está inadimplente no momento.
							<a href="" data-toggle="modal" data-target="#modalDefaulting">Você precisa falar com o responsável?</a>
						</p>
					</div>
				</div>

				<!--========Tipo, Dias=========-->
				<div class="row" id="TD">
					<div class="d-flex col-12 my-2 mt-3">
						<div class="label d-flex justify-content-end col-lg-3 mr-2">
							<label class="word-custom" for="name-company">Tipo de Conta</label>
						</div>
						<div class="btn-group-account-type">
							<select name="" id="select-plans" class="form-control" @change="selectAccountType()"
								v-model="company.account_type">
								<option value="trial">Trial</option>
								<option value="voip">Voip</option>
								<option value="premium">Premium</option>
								<option value="basic">Básico</option>
								<option value="essential">Light</option>
								<option value="advanced">Basic</option>
								<option value="professional">Pro</option>
							</select>
							<span v-if="company.account_type == 'voip'" class="badge bg-voip">Voip</span>
							<span v-if="company.account_type == 'basic'" class="badge bg-info">Basic</span>
							<span v-if="company.account_type == 'essential'" class="badge bg-success">Light</span>
							<span v-if="company.account_type == 'advanced'" class="badge bg-primary">Basic</span>
							<span v-if="company.account_type == 'professional'" class="badge bg-dark">Pro</span>
							<span v-if="company.account_type == 'premium'" class="badge bg-warning">Premium</span>
						</div>
					</div>
					<div class="d-flex col-12 mt-2 mb-2">
						<div class="label d-flex justify-content-end col-lg-3">
							<label class="word-custom" for="call-package">Pacote de conversas</label>
						</div>
						<div class="form-group col-lg-3">
							<input type="text" name="call-package" id="call-package" class="form-control" disabled
								placeholder="numero de conversas" v-model="call_package" />
						</div>
					</div>
					<div
						class="form-group col-lg-7 col-sm-6 d-flex flex-column offset-lg-1 offset-sm-6 justify-content-center align-items-center">
						<p>Consumo atual mês:</p>
						<div class="progress w-50 rounded-pill" style="height: 20px;">
							<div class="progress-bar" role="progressbar" :style="`width:${counterPercent}`">{{ sessionCounter.count }}
							</div>
						</div>
						<div class="d-flex w-50">
							<p class="w-50 text-start">0</p>
							<p class="w-50 text-right">{{ sessionCounter.plan }}</p>
						</div>
					</div>
					<div class="d-flex col-12 mt-2 mb-2">
						<div class="label d-flex justify-content-end col-lg-3">
							<label class="word-custom" for="additional-chips">Chips Adicionais</label>
						</div>
						<div class="form-group col-lg-3">
							<input type="text" name="additional-chips" id="additional-chips" class="form-control" disabled
								placeholder="quantidade de chips" v-model="AdditionalChips" />
						</div>
					</div>
					<div class="d-flex col-12 mt-2 mb-2">
						<div class="label d-flex justify-content-end col-lg-3">
							<label class="word-custom" for="focal_name">Nome Ponto focal financeiro</label>
						</div>
						<div class="form-group col-lg-3">
							<input type="text" name="focal_name" id="focal_name" class="form-control" disabled
								placeholder="Digite o nome focal financeiro" v-model="focalData.focal_name" />
						</div>
					</div>
					<div class="d-flex col-12 mt-2 mb-2">
						<div class="label d-flex justify-content-end col-lg-3">
							<label class="word-custom" for="focal_phone_finance">Telefone do Ponto focal financeiro</label>
						</div>
						<div class="form-group col-lg-3">
							<input type="text" name="focal_phone_finance" id="focal_phone_finance" class="form-control" disabled
								placeholder="Telefone do financeiro" v-model="focalData.focal_phone_finance" />
						</div>
					</div>
					<div class="d-flex col-12 mt-2 mb-2">
						<div class="label d-flex justify-content-end col-lg-3">
							<label class="word-custom" for="focal_email_finance">Email do ponto focal do financeiro</label>
						</div>
						<div class="form-group col-lg-3">
							<input type="text" name="focal_email_finance" id="focal_email_finance" class="form-control" disabled
								placeholder="Email do financeiro" v-model="focalData.focal_email_finance" />
						</div>
					</div>
					<div class="d-flex col-12 mt-2 mb-2">
						<div class="label d-flex justify-content-end col-lg-3">
							<label class="word-custom" for="focal_name_project">Nome Ponto focal projeto</label>
						</div>
						<div class="form-group col-lg-3">
							<input type="text" name="focal_name_project" id="focal_name_project" class="form-control" disabled
								placeholder="Digite o nome focal projeto" v-model="focalData.focal_name_project" />
						</div>
					</div>
					<div class="d-flex col-12 mt-2 mb-2">
						<div class="label d-flex justify-content-end col-lg-3">
							<label class="word-custom" for="focal_phone_project">Telefone do Ponto focal projeto</label>
						</div>
						<div class="form-group col-lg-3">
							<input type="text" name="focal_phone_project" id="focal_phone_project" class="form-control" disabled
								placeholder="(00) 00000 0000" v-model="focalData.focal_phone_project" />
						</div>
					</div>
					<div class="d-flex col-12 mt-2 mb-2">
						<div class="label d-flex justify-content-end col-lg-3">
							<label class="word-custom" for="focal_email_project">Email do ponto focal do projeto</label>
						</div>
						<div class="form-group col-lg-3">
							<input type="text" name="focal_email_project" id="focal_email_project" class="form-control" disabled
								placeholder="Email do representante do projeto" v-model="focalData.focal_email_project" />
						</div>
					</div>
					<div class="d-flex col-12 mt-2 mb-2">
						<div class="label d-flex justify-content-end col-lg-3">
							<label class="word-custom" for="">Acesso à Plataforma</label>
						</div>
						<div class="col-lg-6">
							<div class="form-group mb-0">
								<label class="control-label checkbox-inline mr-4 mb-0">
									<input type="radio" :value="false" v-model="company.account_disabled" :disabled="!$store.state.admin.is_admin ||
										['seller', 'author'].includes($store.state.admin.perfil)
										" />
									<span class="word-custom-radio">Ativado</span>
								</label>
								<label class="control-label checkbox-inline mb-0">
									<input type="radio" :value="true" v-model="company.account_disabled" :disabled="!$store.state.admin.is_admin ||
										['seller', 'author'].includes($store.state.admin.perfil)
										" />
									<span class="word-custom-radio">Desativado</span>
								</label>
							</div>

							<span class="text-small">
								Esta função é utilizada para bloquear acesso de todos os funcionários a plataforma.
								<!-- <span style="color: red">( Desabilitada temporariamente ! )</span> -->
							</span>
						</div>
					</div>

					<div class="d-flex col-12 mt-2 mb-2">
						<div class="label d-flex justify-content-end col-lg-3">
							<label class="word-custom" for="">Acesso Experimental</label>
						</div>
						<div class="col-lg-6">
							<div class="form-group mb-0">
								<label class="control-label checkbox-inline mr-4 mb-0">
									<input type="radio" :value="true" v-model="company.account_is_trial" />
									<span class="word-custom-radio">Ativado</span>
								</label>
								<label class="control-label checkbox-inline mb-0">
									<input type="radio" :value="false" v-model="company.account_is_trial" />
									<span class="word-custom-radio">Desativado</span>
								</label>
							</div>

							<span class="text-small">
								Esta função é ultizada para informar se a conta é de teste.
								<!-- <span style="color: red">( Desabilitada temporariamente ! )</span> -->
							</span>
						</div>
					</div>

					<div class="d-flex col-12 ">
						<div class="label d-flex justify-content-end col-lg-3">
							<label class="word-custom" for="account_limit_days">Limite de Usuários Cadastrados</label>
						</div>
						<div class="form-group col-lg-6">
							<div class="d-flex align-items-center">
								<span class="d-flex justify-content-center align-items-center btn-group ml-2">
									<button :disabled="!$store.state.admin.is_admin ||
										['seller', 'author'].includes($store.state.admin.perfil)
										" style="font-weight: 700" class="btn btn-secondary" @click="company.max_included_users--">-</button>
									<input disabled type="number" class="input-max-included-users form-control pl-4"
										v-model.number="company.max_included_users">
									<button :disabled="!$store.state.admin.is_admin" style="font-weight: 700" class="btn btn-secondary"
										@click="company.max_included_users++">+</button>
								</span>

							</div>
							<span class="text-small">
								Esta função permite cadastrar mais usuários.
							</span>
						</div>
					</div>

					<div class="d-flex col-12" v-if="company.data_trial.status === 'expired'">
						<div class="label d-flex justify-content-end col-lg-3">
							<label class="word-custom" for="account_limit_days">Data de expiração do trial</label>
						</div>
						<div class="form-group col-lg-6">
							{{ new Date(company.data_trial.end_date).toLocaleDateString('pt-BR', {
								day: 'numeric',
								month: 'long',
								year: 'numeric',
								hour: 'numeric',
								minute: 'numeric',
								second: 'numeric',
							}) }}
						</div>
					</div>
				</div>
				<hr />

				<!--========Nome, Razão, CNPJ=========-->
				<div class="row " id="NRC">
					<div class="d-flex col-12 mt-2">
						<div class="label d-flex justify-content-end col-lg-3">
							<label class="word-custom" for="name-company">Nome</label>
						</div>
						<div class="form-group col-lg-6">
							<input type="text" name="name-company" id="name-company" class="form-control" placeholder="Nome da Empresa"
								v-model="company.name" />
						</div>
					</div>

					<div class="d-flex col-12">
						<div class="label d-flex justify-content-end col-lg-3">
							<label class="word-custom" for="corporate-name">Razão Social</label>
						</div>
						<div class="form-group col-lg-6">
							<input type="text" name="corporate-name" id="corporate-name" class="form-control" placeholder="Razão Social"
								v-model="company.razao_social" />
						</div>
					</div>

					<div class="d-flex col-12">
						<div class="label d-flex justify-content-end col-lg-3">
							<label class="word-custom">CNPJ</label>
						</div>
						<div class="form-group col-lg-3">
							<input type="text" name="cnpj" id="cnpj" class="form-control" placeholder="CNPJ" v-model="company.cnpj" />
						</div>
					</div>
				</div>
				<hr />

				<!--=========Encerramento Automático========-->
				<div class="row" id="EA">
					<div class="d-flex col-12 mt-2">
						<div class="label d-flex justify-content-end col-lg-3">
							<label class="word-custom">Encerramento Automático</label>
						</div>
						<div class="col-lg-6">
							<div class="form-group">
								<label class="control-label checkbox-inline mr-4" tyle="margin-left: -20px;">
									<input type="radio" :value="1" v-model="company.auto_close_calls" />
									<span class="word-custom-radio">Ativado</span>
								</label>
								<label class="control-label checkbox-inline" tyle="margin-left: -20px;">
									<input type="radio" :value="0" v-model="company.auto_close_calls" />
									<span class="word-custom-radio">Desativado</span>
								</label>
							</div>

							<span class="text-small">
								Esta função permite encerrar automaticamente atendimentos
								cujo o status seja "em aberto".
							</span>
						</div>
					</div>

					<div class="d-flex col-12 mt-4">
						<div class="col-lg-3"></div>
						<div class="form-group d-flex flex-column justify-content-start align-items-start col-lg-9">
							<div class="d-flex align-items-center col-3 ml-0 pl-0">
								<input type="number" name="close_calls_after" id="close_calls_after" class="form-control mr-2"
									placeholder="1" min="1" v-model.number="company.close_calls_after" />
								<span class="word-custom-small">Hora(s)</span>
							</div>
							<span class="text-small">
								Informar tempo mínimo para encerramento automático de atendimentos em Horas.
							</span>
						</div>
					</div>
				</div>
				<hr />

				<!--========Protocolos=========-->
				<div class="row" id="PRT">
					<div class="d-flex col-12 my-3">
						<div class="label d-flex justify-content-end col-lg-3">
							<label class="word-custom">Protocolos</label>
						</div>
						<div class="col-lg-9">
							<div class="form-group">
								<label class="control-label checkbox-inline mr-4" tyle="margin-left: -20px;">
									<input type="radio" name="generate_protocol" :value="true" v-model="company.generate_protocol" />
									<span class="word-custom-radio">Ativado</span>
								</label>
								<label class="control-label checkbox-inline" tyle="margin-left: -20px;">
									<input type="radio" name="generate_protocol" :value="false" v-model="company.generate_protocol" />
									<span class="word-custom-radio">Desativado</span>
								</label>
							</div>

							<span class="text-small">Utilizar sistema automático de protocolos para atendimentos.</span>
						</div>
					</div>
				</div>
				<hr />

				<!--=========Ocultar Inicio e término========-->
				<div class="row" id="OIT">
					<div class="d-flex col-12 mt-2 my-3">
						<div class="label d-flex justify-content-end col-lg-3">
							<label class="word-custom">Ocultar Inicio e Término</label>
						</div>
						<div class="col-lg-9">
							<div class="form-group">
								<label class="control-label checkbox-inline mr-4" tyle="margin-left: -20px;">
									<input type="radio" name="hide_start_and_end" :value="1" v-model="company.hide_start_and_end" />
									<span class="word-custom-radio">Ativado</span>
								</label>
								<label class="control-label checkbox-inline" tyle="margin-left: -20px;">
									<input type="radio" name="hide_start_and_end" :value="0" v-model="company.hide_start_and_end" />
									<span class="word-custom-radio">Desativado</span>
								</label>
							</div>
							<span class="text-small">
								Cliente não receberá mais a mensagem informativa para os eventos de
								Início e Término do Atendimento.
							</span>
						</div>
					</div>
				</div>
				<hr />

				<!--=========Ocultar Posição em Fila========-->
				<div class="row" id="OPF">
					<div class="d-flex col-12 my-3">
						<div class="label d-flex justify-content-end col-lg-3">
							<label class="word-custom">Ocultar Posição em Fila</label>
						</div>
						<div class="col-lg-9">
							<div class="form-group">
								<label class="control-label checkbox-inline mr-4" tyle="margin-left: -20px;">
									<input type="radio" name="hide_queue_position" :value="1" v-model="company.hide_queue_position" />
									<span class="word-custom-radio">Ativado</span>
								</label>
								<label class="control-label checkbox-inline" tyle="margin-left: -20px;">
									<input type="radio" name="hide_queue_position" :value="0" v-model="company.hide_queue_position" />
									<span class="word-custom-radio">Desativado</span>
								</label>
							</div>

							<span class="text-small">
								Ocultar posição do cliente/lead na fila de espera dos atendimentos.
							</span>
						</div>
					</div>
				</div>
				<hr />

				<!--=========Ocultar Transferência========-->
				<div class="row" id="OT">
					<div class="d-flex col-12 my-3">
						<div class="label d-flex justify-content-end col-lg-3">
							<label class="word-custom">Ocultar Transferência</label>
						</div>
						<div class="col-lg-9">
							<div class="form-group">
								<label class="control-label checkbox-inline mr-4" tyle="margin-left: -20px;">
									<input type="radio" name="hide_transfer" :value="1" v-model="company.hide_transfer" />
									<span class="word-custom-radio">Ativado</span>
								</label>
								<label class="control-label checkbox-inline" tyle="margin-left: -20px;">
									<input type="radio" name="hide_transfer" :value="0" v-model="company.hide_transfer" />
									<span class="word-custom-radio">Desativado</span>
								</label>
							</div>
							<span class="text-small">
								Cliente não receberá mais a mensagem informativa para o evento de
								Transferência do Atendimento.
							</span>
						</div>
					</div>
				</div>
				<hr />

				<!--=========Tabulação========-->
				<div class="row" id="TAB">
					<div class="d-flex col-12 my-3">
						<div class="label d-flex justify-content-end col-lg-3">
							<label class="word-custom">Tabulação</label>
						</div>
						<div class="col-lg-9">
							<div class="form-group">
								<label class="control-label checkbox-inline mr-4" tyle="margin-left: -20px;">
									<input type="radio" name="tabulation_mode" :value="1" v-model="company.tabulation_mode" />
									<span class="word-custom-radio">Ativado</span>
								</label>
								<label class="control-label checkbox-inline" tyle="margin-left: -20px;">
									<input type="radio" name="tabulation_mode" :value="0" v-model="company.tabulation_mode" />
									<span class="word-custom-radio">Desativado</span>

								</label>
							</div>

							<span class="text-small">
								Habilitar sistema de Tabulação dos atendimentos.
								Organiza e Categoriza cada atendimento encerrado "manualmente" no sistema.
							</span>
							<div class="definicoes mt-3">
								<b class="word-custom-small">Definições:</b>
								<ol>
									<li class="li-info">
										Registro de Tabulações
										<i>
											<code class="code">Configurações > Empresa > Tabulação > Cadastrar</code>
										</i>
									</li>
								</ol>
							</div>
						</div>
					</div>
				</div>
				<hr />

				<!--=========Setores========-->
				<div class="row" id="SET">
					<div class="d-flex col-12 my-3">
						<div class="label d-flex justify-content-end col-lg-3">
							<label class="word-custom">Setores</label>
						</div>
						<div class="col-lg-9">
							<div class="form-group">
								<label class="control-label checkbox-inline mr-4" tyle="margin-left: -20px;">
									<input type="radio" name="department_mode" :value="1" v-model.number="company.department_mode" />
									<span class="word-custom-radio">Ativado</span>
								</label>
								<label class="control-label checkbox-inline" tyle="margin-left: -20px;">
									<input type="radio" name="department_mode" :value="0" v-model.number="company.department_mode" />
									<span class="word-custom-radio">Desativado</span>
								</label>
							</div>

							<span class="text-small mb-4">
								Habilitar sistema de atendimento por setores. Seus atendimentos poderão ser tratados
								de forma particular por profissionais destinados especificamente aos setores de
								atendimentos.
							</span>

							<div class="definicoes mt-3">
								<b class="word-custom-small">Definições:</b>
								<ol>
									<li class="li-info">
										Registro de Tabulações
										<i>
											<code class="code">Configurações > Empresa > Tabulação > Cadastrar</code>
										</i>
									</li>
									<li class="li-info">
										Delegar funcionários a setores
										<code class="code">Configurações > Empresa > Setores > Funcionários</code>
									</li>
								</ol>
							</div>
						</div>
					</div>
				</div>
				<hr />

				<!-- =========Limitar Tentativas de Interações======== -->
				<div class="row" id="LTI">
					<section class="box-config">

						<label class="word-custom mb-1">Limitar Tentativas de Interações:</label>
						<span class="text-small mb-1">Habilitar sistema para controle de tentativas de interações com o Bot.
							Redirecionar atendimento para Fila em caso de excesso de erros como resposta na conversa.</span>

						<div class="form-group">
							<label class="control-label checkbox-inline mr-4" tyle="margin-left: -20px;">
								<input type="radio" name="limit_attempts_interactions_status" :value="1"
									v-model="company.limit_attempts_interactions.status" />
								<span class="word-custom-radio"> Ativado</span>
							</label>
							<label class="control-label checkbox-inline" tyle="margin-left: -20px;">
								<input type="radio" name="limit_attempts_interactions_status" :value="0"
									v-model="company.limit_attempts_interactions.status" />
								<span class="word-custom-radio"> Desativado</span>
							</label>
						</div>

						<div v-if="company.limit_attempts_interactions.status == 1" class="form-group w-100">
							<span class="text-small">
								Informar número máximo de tentativas de interações com o Bot.
							</span>

							<input type="number" class="form-control col-lg-2" name="limit_attempts_interactions_time_quantity" min="1"
								placeholder="1" v-model.number="company.limit_attempts_interactions.times_quantity" />
						</div>

						<div v-if="company.limit_attempts_interactions.status == 1"
							class="form-group w-100 d-flex flex-column justify-content-start">
							<span class="text-small">Defina a ação que o sistema deverá realizar, caso o limite seja atingido.</span>

							<label class="control-label" tyle="margin-left: -20px;">
								<input type="radio" name="limit_attempts_interactions_action" value="go_to_queue"
									v-model="company.limit_attempts_interactions.action" />
								<span class="word-custom-radio"> Encaminhar para Fila de Atendimento</span>
							</label>

							<label class="control-label " tyle="margin-left: -20px;">
								<input type="radio" name="limit_attempts_interactions_action" value="go_to_flow"
									v-model="company.limit_attempts_interactions.action" />
								<span class="word-custom-radio"> Encaminhar para Fluxo</span>
							</label>

							<label class="control-label " tyle="margin-left: -20px;">
								<input type="radio" name="limit_attempts_interactions_action" value="go_to_previous_department"
									v-model="company.limit_attempts_interactions.action" />
								<span class="word-custom-radio"> Encaminhar para Setor Predefinido</span>
							</label>

							<div class="w-100"
								v-if="company.department_mode == 1 && company.limit_attempts_interactions.action === 'go_to_queue'">
								<b class="word-custom-small">Setor Padrão:</b>

								<div class="form-group col-lg-5 p-0">
									<select name id class="form-control" v-model="company.limit_attempts_interactions.department">
										<option value="">Selecione...</option>
										<option v-for="(departament, i) in company.departments" :key="i" :value="departament.label">
											{{ departament.name }}
										</option>
									</select>
								</div>
							</div>

							<div class="w-100" v-else-if="company.limit_attempts_interactions.action === 'go_to_flow'">
								<b class="word-custom-small">Fluxo Padrão:</b>

								<div class="form-group col-lg-5 p-0">
									<select name id class="form-control" v-model="company.limit_attempts_interactions.flow">
										<option value="">Selecione...</option>
										<option v-for="flow in flows" :key="flow._id" :value="flow._id">
											{{ flow.content.title }}
										</option>
									</select>
								</div>
							</div>

							<div class="w-100" v-else-if="company.limit_attempts_interactions.action === 'go_to_previous_department'">
								<b class="word-custom-small">Setor predefinido: </b>
								<span class="text-small mb-2"> Direciona o cliente para o último setor ao qual ele interagiu.</span>
							</div>
						</div>

						<div class="w-100">
							<div class="form-group col-lg-10 p-0">
								<p class="word-custom-small mb-0">Frase Padrão:</p>
								<span class="text-small">
									Frase padrão para informação do evento. Esta frase irá aparecer para o
									Cliente/Lead antes do mesmo ser encaminhado para a fila de atendimento.
								</span>

								<textarea class="form-control col-lg-10 mt-2 p-3" cols="20" rows="3"
									name="limit_attempts_interactions_message" v-model="company.limit_attempts_interactions.message">
								</textarea>
							</div>
						</div>
					</section>
				</div>
				<hr />

				<!--========Expediente Semanal=========-->
				<div class="row" id="ES">
					<div class="label d-flex justify-content-end col-lg-3">
						<label class="word-custom">Expediente Semanal</label>
					</div>
					<div class="col-lg-7">
						<table class="table table-striped table-condensed table-hover table-sm">
							<tbody>
								<tr v-for="(configDay, day, i) in company.weekly_schedule" :key="i" style="height: 10px">
									<td style="width: 140px" class="">
										<label :for="day" style="width: 100px" class="d-flex justify-content-end align-items-center">
											<span class="word-custom-radio">{{ changeNameDay(day) }}</span>
											<input type="checkbox" value="on" :id="day" class="ml-1" v-model="configDay.active" />
										</label>
									</td>
									<td>
										<div class="d-flex align-items-center justify-content-start">
											<div style="width: 90px; cursor:pointer">
												<vue-clock-picker input-class="form-control form-control-sm background-clock"
													v-model="configDay.start" active-color="#1970b6" placeholder="00:00" :close-on-overlay="true">
												</vue-clock-picker>
											</div>
											<span class="mx-2">às</span>
											<div style="width: 90px; cursor:pointer">
												<vue-clock-picker input-class=" form-control form-control-sm" v-model="configDay.end"
													active-color="#1970b6" placeholder="00:00" :close-on-overlay="true">
												</vue-clock-picker>
											</div>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<hr />

				<!--========Dados endereço=========-->
				<div class="row" id="DE">
					<div class="d-flex col-12 mt-2">
						<div class="label d-flex justify-content-end col-lg-3">
							<label class="word-custom" for="zip_code">CEP</label>
						</div>
						<div class="form-group col-lg-2">
							<input type="text" name="zip_code" id="zip_code" class="form-control" placeholder="Informe CEP"
								v-model="company.address.zip_code" />
						</div>
					</div>

					<div class="d-flex col-12">
						<div class="label d-flex justify-content-end col-lg-3">
							<label class="word-custom" for="address_number">Número</label>
						</div>
						<div class="form-group col-lg-2">
							<input type="text" name="address_number" id="address_number" class="form-control"
								placeholder="Informar Número" v-model="company.address.number" />
						</div>
					</div>

					<div class="d-flex col-12">
						<div class="label d-flex justify-content-end col-lg-3">
							<label class="word-custom" for="street">Rua</label>
						</div>
						<div class="form-group col-lg-4">
							<input type="text" name="street" id="street" class="form-control" placeholder="Informar Rua"
								v-model="company.address.street" />
						</div>
					</div>

					<div class="d-flex col-12">
						<div class="label d-flex justify-content-end col-lg-3">
							<label class="word-custom" for="district">Bairro</label>
						</div>
						<div class="form-group col-lg-3">
							<input type="text" name="district" id="district" class="form-control" placeholder="Informar Bairro"
								v-model="company.address.district" />
						</div>
					</div>

					<div class="d-flex col-12">
						<div class="label d-flex justify-content-end col-lg-3">
							<label class="word-custom">Cidade</label>
						</div>
						<div class="form-group col-lg-3">
							<select class="form-control" v-model="company.address.city">
								<option value="">Selecione...</option>
								<option v-for="(city, i) in citiesBasic" :key="i" :value="city">
									{{ city }}
								</option>
							</select>
						</div>
					</div>

					<div class="d-flex col-12">
						<div class="label d-flex justify-content-end col-lg-3">
							<label class="word-custom">Estado</label>
						</div>
						<div class="form-group col-lg-3">
							<select name id class="form-control" v-model="company.address.state">
								<option value="">Selecione...</option>
								<option v-for="(state, i) in states" :key="i" :value="state.acronym">
									{{ state.name }}
								</option>
							</select>
						</div>
					</div>
				</div>
				<hr />

				<!--========Fuso Horário=========-->
				<div class="row" id="FSH">
					<div class="d-flex col-12 mt-4">
						<div class="label d-flex justify-content-end col-lg-3">
							<label class="word-custom">Fuso Horário</label>
						</div>
						<div class="form-group col-lg-4">
							<select class="form-control" name="settings[timezone]" v-model="company.settings.timezone">
								<option value="">Selecione...</option>
								<option v-for="($tz, i) in helpers.getSystemTimezones()" :key="i" :value="$tz.value">{{ $tz.label }}
								</option>
							</select>
							<span class="text-small">Definição para fuso horário geral do sistema.</span>
						</div>
					</div>
				</div>
				<hr />


				<!-- Parte das integrações -->

				<!-- integração whatsapp -->
				<div class="row div-whatsapp m-0" id="INT">
					<div class="d-flex col-12 my-3">
						<div class="label d-flex justify-content-end col-lg-3">
							<label class="word-custom">Integrações Whatsapps Tallos</label>
						</div>
						<div class="div-elements-integrations form-group col-lg-8 d-flex flex-wrap justify-content-start">
							<div class="d-flex align-items-center m-1 mr-4" v-for="(value, chave, i) in arrayWhatsapp" :key="i">
								<!-- <img src="@/assets/images/whatsapp.png" width="35" height="25"/>     -->
								<input type="text" class="form-control mx-1 " :readonly="releaseReadWhatsapp" v-model="value.label">

								<!-- <button class="btn btn-danger my-1 " @click="deleteIntegration('whatsapp', chave)">
									<i class="fa fa-trash"></i>
								</button> -->
							</div>
						</div>
						<!-- <div class="form-group col-lg-1 d-flex flex-column justify-content-start">
							<button class="btn btn-primary m-1" data-toggle="tooltip" data-placement="right"
								title="adicionar integração whatsapp" @click="insertIntegration('whatsapp')">
								<i class="fa fa-plus"></i>
							</button>
							<button class="btn btn-default m-1" data-toggle="tooltip" data-placement="right" title="permitir escrita"
								@click="releaseReadWhatsapp = !releaseReadWhatsapp">
								<i class="fa fa-pencil-alt"></i>
							</button>
						</div> -->
					</div>
				</div>
				<hr />

				<!-- integração whatsapp official -->
				<div class="row div-whatsapp-official m-0">
					<div class="d-flex col-12 my-3">
						<div class="label d-flex justify-content-end col-lg-3">
							<label class="word-custom">Integrações Whatsapps Oficiais</label>
						</div>
						<div class="div-elements-integrations form-group col-lg-8 d-flex flex-wrap justify-content-start">
							<div class="d-flex align-items-center m-1 mr-4" v-for="(value, chave, i) in arrayWhatsappOfficial" :key="i">
								<!-- <img src="@/assets/images/whatsapp.png" width="35" height="25"/>     -->
								<input type="text" class="form-control mx-1 " :readonly="releaseReadWhatsappOfficial"
									v-model="value.label">

								<button class="btn btn-danger my-1 " @click="deleteIntegration('whatsapp-official', chave)">
									<i class="fa fa-trash"></i>
								</button>
							</div>
						</div>
						<div class="form-group col-lg-1 d-flex flex-column justify-content-start">
							<button class="btn btn-primary m-1" data-toggle="tooltip" data-placement="right"
								title="adicionar integração whatsapp oficial" @click="insertIntegration('whatsapp-official')">
								<i class="fa fa-plus"></i>
							</button>
							<button class="btn btn-default m-1" data-toggle="tooltip" data-placement="right" title="permitir escrita"
								@click="releaseReadWhatsappOfficial = !releaseReadWhatsappOfficial">
								<i class="fa fa-pencil-alt"></i>
							</button>
						</div>
					</div>
				</div>
				<hr />

				<!-- integração instagram -->
				<div class="row div-instagram m-0">
					<div class="d-flex col-12 my-3">
						<div class="label d-flex justify-content-end col-lg-3">
							<label class="word-custom">Integrações Instagrams</label>
						</div>
						<div class="div-elements-integrations form-group col-lg-8 d-flex flex-wrap justify-content-start">
							<div class="d-flex align-items-center m-1 mr-4" v-for="(value, chave, i) in arrayInstagram" :key="i">
								<!-- <img src="@/assets/images/whatsapp.png" width="35" height="25"/>     -->
								<input type="text" class="form-control mx-1 " :readonly="releaseReadInstagram" v-model="value.label">

								<button class="btn btn-danger my-1 " @click="deleteIntegration('instagram', chave)">
									<i class="fa fa-trash"></i>
								</button>
							</div>
						</div>
						<div class="form-group col-lg-1 d-flex flex-column justify-content-start">
							<button class="btn btn-primary m-1" data-toggle="tooltip" data-placement="right"
								title="adicionar integração instagram" @click="insertIntegration('instagram')">
								<i class="fa fa-plus"></i>
							</button>
							<button class="btn btn-default m-1" data-toggle="tooltip" data-placement="right" title="permitir escrita"
								@click="releaseReadInstagram = !releaseReadInstagram">
								<i class="fa fa-pencil-alt"></i>
							</button>
						</div>
					</div>
				</div>
				<hr />

				<!-- integração widget -->
				<div class="row div-widget m-0">
					<div class="d-flex col-12 my-3">
						<div class="label d-flex justify-content-end col-lg-3">
							<label class="word-custom">Integrações Widgets</label>
						</div>
						<div class="div-elements-integrations form-group col-lg-8 d-flex flex-wrap justify-content-start">
							<div class="d-flex align-items-center m-1 mr-4" v-for="(value, chave, i) in arrayWidget" :key="i">
								<!-- <img src="@/assets/images/whatsapp.png" width="35" height="25"/>     -->
								<input type="text" class="form-control mx-1 " :readonly="releaseReadWidget" v-model="value.label">

								<button class="btn btn-danger my-1" @click="deleteIntegration('widget', chave)">
									<i class="fa fa-trash"></i>
								</button>
							</div>
						</div>
						<div class="form-group col-lg-1 d-flex flex-column justify-content-start">
							<button class="btn btn-primary m-1" data-toggle="tooltip" data-placement="right"
								title="adicionar integração widget" @click="insertIntegration('widget')">
								<i class="fa fa-plus"></i>
							</button>
							<button class="btn btn-default m-1" data-toggle="tooltip" data-placement="right" title="permitir escrita"
								@click="releaseReadWidget = !releaseReadWidget">
								<i class="fa fa-pencil-alt"></i>
							</button>
						</div>
					</div>
				</div>
				<hr />

				<!-- integração telegrams -->
				<div class="row div-telegram m-0">
					<div class="d-flex col-12 my-3">
						<div class="label d-flex justify-content-end col-lg-3">
							<label class="word-custom">Integrações Telegrams</label>
						</div>
						<div class="div-elements-integrations form-group col-lg-8 d-flex flex-wrap justify-content-start">
							<div class="d-flex align-items-center m-1 mr-4" v-for="(value, chave, i) in arrayTelegram" :key="i">
								<!-- <img src="@/assets/images/whatsapp.png" width="35" height="25"/>     -->
								<input type="text" class="form-control mx-1 " :readonly="releaseReadTelegram" v-model="value.label">

								<button class="btn btn-danger my-1" @click="deleteIntegration('telegram', chave)">
									<i class="fa fa-trash"></i>
								</button>
							</div>
						</div>
						<div class="form-group col-lg-1 d-flex flex-column justify-content-start">
							<button class="btn btn-primary m-1" data-toggle="tooltip" data-placement="right"
								title="adicionar integração telegram" @click="insertIntegration('telegram')">
								<i class="fa fa-plus"></i>
							</button>
							<button class="btn btn-default m-1" data-toggle="tooltip" data-placement="right" title="permitir escrita"
								@click="releaseReadTelegram = !releaseReadTelegram">
								<i class="fa fa-pencil-alt"></i>
							</button>
						</div>
					</div>
				</div>
				<hr />

				<!-- integração emails -->
				<div class="row div-email m-0">
					<div class="d-flex col-12 my-3">
						<div class="label d-flex justify-content-end col-lg-3">
							<label class="word-custom">Integrações Email</label>
						</div>
						<div class="div-elements-integrations form-group col-lg-8 d-flex flex-wrap justify-content-start">
							<div class="d-flex align-items-center m-1 mr-4" v-for="(value, chave, i) in arrayEmail" :key="i">
								<!-- <img src="@/assets/images/whatsapp.png" width="35" height="25"/>     -->
								<input type="text" class="form-control mx-1 " :readonly="releaseReadEmail" v-model="value.label">

								<button class="btn btn-danger my-1" @click="deleteIntegration('email', chave)">
									<i class="fa fa-trash"></i>
								</button>
							</div>
						</div>
						<div class="form-group col-lg-1 d-flex flex-column justify-content-start">
							<button class="btn btn-primary m-1" data-toggle="tooltip" data-placement="right"
								title="adicionar integração email" @click="insertIntegration('email')">
								<i class="fa fa-plus"></i>
							</button>
							<button class="btn btn-default m-1" data-toggle="tooltip" data-placement="right" title="permitir escrita"
								@click="releaseReadEmail = !releaseReadEmail">
								<i class="fa fa-pencil-alt"></i>
							</button>
						</div>
					</div>
				</div>


				<!-- Migração dos contatos -->
				<div class="row" style="background: #0001">
					<div class="d-flex col-12 mt-4">
						<div class="label d-flex justify-content-end col-lg-3">
							<label class="word-custom">Migrar contatos para integração</label>
						</div>
						<div class="form-group col-lg-4">
							<select class="form-control" v-model="contactsIntegrantios">
								<option value="">Selecione...</option>
								<option v-for="(key, value, keyInt) in company.whatsapps" :key="keyInt" :value="value">{{ key.label }}
								</option>
							</select>
						</div>
						<div class="form-group col-lg-5 d-flex justify-content-end">
							<button class="btn btn-primary mr-2" data-toggle="tooltip" data-placement="right" title="migrar contatos"
								@click="migrationContacts()">
								Migrar
							</button>
						</div>
					</div>
				</div>
				<hr />


				<!-- Fim das integrações -->

				<!--========Modules=========-->
				<div class="row" id="MOD">
					<div class="d-flex col-12 my-3">
						<div class="label d-flex justify-content-end col-lg-3">
							<label class="word-custom">Módulos</label>
						</div>
						<div class="form-group col-lg-9">
							<div class="control-label" style="font-weight: normal;">
								<input type="checkbox" id="modules[flow]" name="modules[flow]" class="mr-1" value="1"
									v-model="company.modules.flows" />
								<label for="modules[flow]" class="label-permission">
									<b style="font-weight: bold;" class="word-custom">Flow </b>
									<span class="word-custom-radio">
										- Módulo flow
									</span>
								</label>
							</div>

							<div class="control-label " style="font-weight: normal;">
								<input type="checkbox" id="modules[broadcast]" name="modules[broadcast]" class="mr-1" value="1"
									v-model="company.modules.broadcast" />
								<label for="modules[broadcast]" class="label-permission">
									<b style="font-weight: bold;" class="word-custom">Broadcast</b>
									<span class="word-custom-radio">
										- Módulo broadcast
									</span>
								</label>
							</div>

							<div class="control-label mb-1" style="font-weight: normal;">
								<input type="checkbox" id="modules[activity]" name="modules[activity]" class="mr-1" value="1"
									v-model="company.modules.activity" />
								<label for="modules[activity]" class="label-permission">
									<b style="font-weight: bold;" class="word-custom">Activity</b>
									<span class="word-custom-radio">
										- Módulo activity
									</span>
								</label>
							</div>

							<!-- <span class="text-small ">
                        <b class="word-custom-small">Permissões: </b>
                        Nesta seção, é possível alterar o comportamento da aplicação com base no perfil do usuário. Possibilitando 
                        a apresentação de recursos atualmente disponíveis apenas a Usuários.
                    </span> -->
						</div>
					</div>
				</div>
				<hr />


				<!--========Permissões=========-->
				<div class="row" id="PERM">
					<div class="d-flex col-12 my-5">
						<div class="label d-flex justify-content-end col-lg-3">
							<label class="word-custom">Permissões</label>
						</div>
						<div class="form-group col-lg-9">
							<div class="control-label" style="font-weight: normal;">
								<input type="checkbox" id="permissions_display_reports_calls" name="permissions_display_reports_calls"
									class="mr-1" value="allow" v-model="company.permissions.display_reports_calls" />
								<label for="permissions_display_reports_calls" class="label-permission">
									<b style="font-weight: bold;" class="word-custom">Relatórios </b>
									<span class="word-custom-radio">
										- Exibir "Relatório de Atendimentos" a usuário operadores.
									</span>
								</label>
							</div>

							<div class="control-label mb-0" style="font-weight: normal;">
								<input type="checkbox" id="permissions_display_all_attendances" name="permissions_display_all_attendances"
									class="mr-1" value="allow" v-model="company.permissions.display_all_attendances" />
								<label for="permissions_display_all_attendances" class="label-permission">
									<b style="font-weight: bold;" class="word-custom">Chat</b>
									<span class="word-custom-radio">
										- Exibir opção "Todos Atendimentos" a usuário operadores.
									</span>
								</label>
							</div>

							<div class="control-label mb-0" style="font-weight: normal;">
								<input type="checkbox" id="permission_whatsapp_trial" name="permission_whatsapp_trial" class="mr-1"
									value="allow" v-model="company.permissions.permission_whatsapp_trial" />
								<label for="permission_whatsapp_trial" class="label-permission">
									<b style="font-weight: bold;" class="word-custom">Whatsapp</b>
									<span class="word-custom-radio">
										- Permite usar o whatsapp em conta Trial.
									</span>
								</label>
							</div>

							<div class="control-label mb-3" style="font-weight: normal;">
								<input type="checkbox" id="permission_google_contacts_trial" name="permission_google_contacts_trial"
									class="mr-1" value="allow" v-model="company.permissions.permission_google_contacts_trial" />
								<label for="permission_google_contacts_trial" class="label-permission">
									<b style="font-weight: bold;" class="word-custom">Google Contatos</b>
									<span class="word-custom-radio">
										- Permite usar o Google Gontatos em conta Trial.
									</span>
								</label>
							</div>
							<!-- {{typeof company.permissions.display_reports_calls}} : {{company.permissions.display_reports_calls}}
					{{typeof company.permissions.display_all_attendances}} : {{company.permissions.display_all_attendances}} -->

							<span class="text-small ">
								<b class="word-custom-small">Permissões: </b>
								Nesta seção, é possível alterar o comportamento da aplicação com base no perfil do usuário. Possibilitando
								a apresentação de recursos atualmente disponíveis apenas a Administradores.
							</span>
						</div>
					</div>
				</div>
				<hr />

				<!--========Modais=========-->
				<div class="row" id="PERM">
					<div class="d-flex col-12">
						<div class="label d-flex justify-content-end col-lg-3">
							<label class="word-custom">Modais</label>
						</div>
						<div class="form-group col-lg-9">
							<div class="control-label" style="font-weight: normal;">
								<input type="checkbox" id="modalGeneral" class="mr-1" v-model="company.modalGeneral" />
								<label for="modalGeneral" class="modalGeneral">
									<b style="font-weight: bold;" class="word-custom">Modal de Avaliação </b>
									<span class="word-custom-radio">
										- Habilita o modal de avaliação.
									</span>
								</label>
							</div>


							<span class="text-small ">
								<b class="word-custom-small">Modais: </b>
								Nesta seção, é possível habilitar os modais para a empresa.
							</span>
						</div>
					</div>
				</div>
				<hr />

				<!-- Dados do whatsapp de configurações  -->
				<div class="row" style="background: #0001">
					<div class="d-flex col-12 mt-4">
						<div class="label d-flex justify-content-end col-lg-3">
							<label class="word-custom">Configurações de Whatsapp</label>
						</div>
						<div class="form-group col-lg-4">
							<input type="text" class="form-control mb-2" placeholder="Whatsapp url"
								v-model="company.whatsapp_settings.base_url">
							<input type="number" class="form-control" placeholder="Whatsapp timeout"
								v-model.number="company.whatsapp_settings.timeout">
						</div>
					</div>
				</div>
				<hr />

				<!-- Dados do whatsapp de configurações  -->
				<div class="row">
					<div class="d-flex col-12 mt-4">
						<div class="label d-flex justify-content-end col-lg-3">
							<label class="word-custom">
								Tipos de Integrações do Whatsapp <br>
								<span v-if="company.whatsapps_integrations_type === 'tallos'" class="text-small text-danger mr-4 mb-0">
									Essa empresa ainda não selecionou uma integração
								</span>
							</label>
						</div>
						<div class="form-group mb-0">
							<label for="whatsapp-official-gupshup" class="control-label checkbox-inline mr-4 mb-0">
								<input type="radio" value="gupshup" id="whatsapp-official-gupshup"
									v-model="company.whatsapps_integrations_type" />
								<span class="word-custom-radio">Gupshup</span>
							</label>
							<label for="whatsapp-official-dialog" class="control-label checkbox-inline mr-4 mb-0">
								<input type="radio" value="dialog" id="whatsapp-official-dialog"
									v-model="company.whatsapps_integrations_type" />
								<span class="word-custom-radio">Dialog</span>
							</label>
							<label for="whatsapp-official-motor" class="control-label checkbox-inline mr-4 mb-0">
								<input type="radio" value="motor" id="whatsapp-official-motor"
									v-model="company.whatsapps_integrations_type" />
								<span class="word-custom-radio">RD Motor</span>
							</label>
						</div>
					</div>
				</div>

				<hr />

				<Topic :options="brokerStatusOptions" :data="company" />
				<hr />
				<Topic :options="phraseForwardOptions" :data="company">
					<button v-if="company.forward_phrase" class="btn btn-sm btn-primary"
						@click="handleSubmitForwardPhrase(company._id)">Cadastrar Frase</button>
				</Topic>
				<hr />


				<!-- buttons finals -->
				<div class="row">
					<div class="col-lg-12 my-2 d-flex justify-content-center mb-4">
						<router-link to="/admin/companies" tag="button" class="btn-big btn btn-default">
							Voltar
						</router-link>
						<button class="btn-big btn btn-primary ml-3" data-target="#updateModal" data-toggle="modal">
							Atualizar
						</button>
					</div>
				</div>

			</section>
		</div>
	</div>
</template>

<script>
// ### import libs ### 
import helpers from '@/assets/helpers';
import citiesBasic from "@/assets/scripts/citiesBasic.json";
import states from "@/assets/scripts/states.json";
import axios from 'axios';
import "moment/locale/pt-br.js";

// ### import components ###
import TitlePage from '../../shared/components/title-page/TitlePage.vue';

export default {
	components: {
		TitlePage,
		Topic: () => import('../../shared/components/topic/index.vue'),
		ModalSubmitForwardPhrase: () => import('../../shared/components/modalSubmitForwardPhrase/index.vue'),
	},
	data() {
		return {
			titlePage: 'Editar Configurações da Empresa',
			iconClass: 'fa fa-edit',
			showNavItems: false,
			sessionCounter: {},
			AdditionalChips: 'sem informações',
			company: {},
			flows: [],
			states,
			citiesBasic,
			helpers,
			companyExists: false,
			focalData: {},

			releaseReadWhatsapp: true,
			arrayWhatsapp: [],

			releaseReadInstagram: true,
			arrayInstagram: [],

			releaseReadWidget: true,
			arrayWidget: [],

			releaseReadWhatsappOfficial: true,
			arrayWhatsappOfficial: [],

			releaseReadTelegram: true,
			arrayTelegram: [],

			releaseReadEmail: true,
			arrayEmail: [],

			modalForwardPhraseIsLoading: false,
			forwardPhraseDocument: {
				phrases: ''
			},


			contactsIntegrantios: '',
			integrationInfo: {
				token: '',
				integ: '',
			},

			brokerStatusOptions: {
				name: 'broker_status',
				title: 'Tipo de Contrato',
				options: [
					{
						title: 'Tallos',
						value: true,
					},
					{
						title: 'Broker',
						value: false,
					}
				]
			},

			phraseForwardOptions: {
				name: 'forward_phrase',
				title: 'Frase de encaminhamento',
				options: [
					{
						title: 'Ativo',
						value: true,
					},
					{
						title: 'Inativo',
						value: false,
					}
				]
			},

		};
	},

	computed: {
		call_package() {
			return this.company?.finance_data?.subscription?.data?.sale_item?.id ?
				this.company.finance_data.subscription.data.sale_item.id.split('-')[3] * 1000 :
				'sem informações'
		},
		counterPercent() {
			if (!this.sessionCounter || !this.sessionCounter.count || !this.sessionCounter.plan) {
				this.sessionCounter = {
					count: 0,
					plan: 0
				}
				return '0%'
			}
			else {
				return (this.sessionCounter.count / this.sessionCounter.plan) * 100 + '%'
			}
		}
	},
	methods: {
		getCompany() {
			this.$tallos_admin_api.defaults.headers.common["Authorization"] =
				`Bearer ${this.$store.getters.tokenJWT}`;

			this.$tallos_admin_api
				.get(`companies/${this.$route.params.id}`)
				.then(res => {
					if (res.data.company) {
						res.data.company.modules = res.data.company.modules ? res.data.company.modules : {
							flow: 0,
							broadcast: 0,
							activity: 0
						}
						this.company = res.data.company
						this.company['message'] = res.data.last_message
						this.companyRoot = res.data.company
						this.companyExists = true
						this.getProposal()
						this.getSessionCounter()
						this.getFlows()
					}
				})
				.catch(e => {
					this.$toasted.global.defaultError({ msg: 'Empresa não encontrada.' })
					this.$router.push({ path: '/admin/companies' })
				})

		},

		getFlows() {
			this.$tallos_admin_api.defaults.headers.common["Authorization"] =
				`Bearer ${this.$store.getters.tokenJWT}`;

			this.$tallos_admin_api
				.get(`flow/${this.company._id}/all`)
				.then((res) => {
					this.flows = res.data
				})
				.catch((err) => {
					this.$toasted.global.defaultError({ msg: 'Erro ao buscar flows.' })
				})
		},

		async getProposal() {
			this.$tallos_admin_api.defaults.headers.common["Authorization"] =
				`Bearer ${this.$store.getters.tokenJWT}`;
			this.$tallos_admin_api
				.get(`proposals/getProposalByCNPJ?cnpj=${this.company.cnpj}&id_customer_lead=${this.company.id_customer_lead}`)
				.then(res => {
					this.proposal = res.data
					if (res.data.subscription && res.data.subscription.addons) {
						res.data.subscription.addons.forEach((addon) => {
							if (addon.sale_item.name.match(/chip/g)) {
								this.AdditionalChips = addon.sale_item.name
							}
						})
					}
					this.focalData = res?.data?.extraDatas ? res.data.extraDatas : {
						focal_name: "",
						focal_phone: "",
						focal_email_finance: "",
						focal_phone_finance: "",
						focal_name_project: "",
						focal_phone_project: "",
						focal_email_project: ""
					}
				})
		},

		getSessionCounter() {
			this.$tallos_admin_api.defaults.headers.common["Authorization"] =
				`Bearer ${this.$store.getters.tokenJWT}`;

			this.$tallos_admin_api
				.get(`sessionsCount/getSession?company=${this.company._id}`)
				.then(res => {
					this.sessionCounter = res.data
					this.sessionCounter.plan = (this.sessionCounter?.plan || this.company.finance_data?.subscription?.data?.sale_item?.id)?.split('-')[3] * 1000
				})
				.catch(e => {
					this.sessionCounter = {
						count: 0,
						plan: 0
					}
				})
		},

		updateCompany() {
			// validar habilitação do sistema de tabulação
			if (this.company.tabulation_mode == 1
				&& this.company.tabulations.length === 0) {
				this.$toasted.global.defaultError({
					msg: `Para habilitar o sistema de Tabulação, 
				você precisa ter cadastrado no mínimo um item de tabulação.`});
				return false;
			}

			// validar habilitação do sistema de atendimento por setores
			if (this.company.department_mode == 1
				&& this.company.departments.length === 0) {
				this.$toasted.global.defaultError({
					msg: `Para habilitar o sistema de atendimento 
				por setores, você precisa ter cadastrado no mínimo um setor.`})
				return false;
			}

			//valida limite de interacões com setores
			if (this.company.limit_attempts_interactions.status == 1) {
				const limitAttemptsInteractions = this.company.limit_attempts_interactions;

				if (limitAttemptsInteractions.times_quantity <= 0) {
					this.$toasted.global.defaultError({ msg: `O campo "Número de Tentativas" para o limite de interações deve ser maior que 0.` })
					return false;
				}

				if (limitAttemptsInteractions.action === 'go_to_queue' && !limitAttemptsInteractions.department) {
					this.$toasted.global.defaultError({ msg: `O campo "Setor" para o limite de interações é obrigatório.` })
					return false;
				}

				if (limitAttemptsInteractions.action === 'go_to_flow' && !this.company.limit_attempts_interactions.flow) {
					this.$toasted.global.defaultError({ msg: `O campo "Fluxo" para o limite de interações é obrigatório.` })
					return false;
				}
			}

			// tratar valores
			const companyModified = this.modifyAttributesCompany(this.company)

			this.aceptIntegrations()

			this.$tallos_admin_api.defaults.headers.common["Authorization"] =
				`Bearer ${this.$store.getters.tokenJWT}`;
			this.$tallos_admin_api
				.put(`companies/${this.$route.params.id}`,
					{
						user: this.$store.getters.admin,
						...companyModified
					})
				.then(res => {
					this.$toasted.global.defaultSuccess({ msg: 'Os dados foram atualizados com sucesso.' })
					this.$router.push({ path: '/admin/companies' })
				})
				.catch(e => {
					this.$toasted.global.defaultError({ msg: 'Erro ao atualizar.' })
				})
		},

		changeNameDay(day) {
			if (day == 'monday') {
				return 'Segunda'
			} else if (day == 'tuesday') {
				return 'Terça'
			} else if (day == 'wednesday') {
				return 'Quarta'
			} else if (day == 'thursday') {
				return 'Quinta'
			} else if (day == 'friday') {
				return 'Sexta'
			} else if (day == 'saturday') {
				return 'Sábado'
			} else if (day == 'sunday') {
				return 'Domingo'
			} else {
				return 'Nenhum'
			}
		},

		modifyAttributesCompany(company) {

			company.auto_close_calls = company.auto_close_calls == 1 ? 'true' : false
			company.hide_start_and_end = company.hide_start_and_end == 1 ? 'true' : false
			company.hide_queue_position = company.hide_queue_position == 1 ? 'true' : false
			company.hide_transfer = company.hide_transfer == 1 ? 'true' : false

			company.department_mode = company.department_mode == 1 ? 'true' : false
			company.tabulation_mode = company.tabulation_mode == 1 ? 'true' : false

			company.limit_attempts_interactions.status =
				company.limit_attempts_interactions.status == 1 ? 'true' : false

			//valida se day está ativo, se ativo recebe "on", se não é deletado 
			for (let day in company.weekly_schedule) {
				if (company.weekly_schedule[day].active) {
					company.weekly_schedule[day].active = "on"
				} else {
					delete company.weekly_schedule[day].active
				}
			}

			//valida os módulos se ativo recebe 1 se não é deletado
			for (const type_module in company.modules) {
				if (company.modules[type_module]) {
					company.modules[type_module] = company.modules[type_module]
					// console.log(type_module)
				} else {
					delete company.modules[type_module]
				}
			}
			// console.log(company.weekly_schedule[day].active)

			return company
		},

		validateIntegrationNumber(integration) {
			if (integration.length > 0) {
				let IntegrationNumber = (parseInt(integration[integration.length - 1].split('-')[1]) + 1)
				return IntegrationNumber
			} else {
				return 1
			}
		},

		//métodos das integrations
		insertIntegration(wiw) {
			if (wiw == 'whatsapp') {//whatsapps
				const integrationsWhatsapps = Object.keys(this.company.whatsapps)
				let whatsappNameIntegration = this.validateIntegrationNumber(integrationsWhatsapps)

				this.company.whatsapps[`integration-${whatsappNameIntegration}`] = {
					access_token: "",
					label: `Chip ${whatsappNameIntegration}`,
					connection: 0,
					session: 0
				}

				this.arrayWhatsapp = { ...this.company.whatsapps }

			} else if (wiw == 'instagram') {//instagrams
				const integrationsInstagrams = Object.keys(this.company.instagrams)
				let nameIntegration = this.validateIntegrationNumber(integrationsInstagrams)

				this.company.instagrams[`integration-${nameIntegration}`] = {
					access_token: "",
					label: `Instagram ${nameIntegration}`,

				}

				this.arrayInstagram = { ...this.company.instagrams }

			} else if (wiw == 'widget') {//widgets
				const integrationsWidgets = Object.keys(this.company.widgets)
				let WidgetsNameIntegration = this.validateIntegrationNumber(integrationsWidgets)

				this.company.widgets[`integration-${WidgetsNameIntegration}`] = {
					label: `Site ${WidgetsNameIntegration}`,
					phrase: "Olá, antes de iniciarmos o atendimento precisamos coletar algumas informações",
					fields: [],
					customized_fields: [],
					color: "#DF0D6A",
					tracking: false,
					logo: "",
					phrase_presentation: "",
					side: "right",
					size: "big",
					active_phrase_presentation: false,
				}

				this.arrayWidget = { ...this.company.widgets }
			} else if (wiw == 'whatsapp-official') {//whatsapps oficiais
				const integrationsWhatsappsOfficial = Object.keys(this.company.official_whatsapps)
				let WhatsappsOfficialNameIntegration = this.validateIntegrationNumber(integrationsWhatsappsOfficial)

				this.company.official_whatsapps[`integration-${WhatsappsOfficialNameIntegration}`] = {
					label: `Chip ${WhatsappsOfficialNameIntegration}`,
					broker: "",
					phone: "",
					access_token: "",
					app: ""
				}

				this.arrayWhatsappOfficial = { ...this.company.official_whatsapps }
			} else if (wiw == 'telegram') {//telegrams
				const integrationsTelegrams = Object.keys(this.company.telegrams)
				let TelegramsNameIntegration = this.validateIntegrationNumber(integrationsTelegrams)

				this.company.telegrams[`integration-${TelegramsNameIntegration}`] = {
					label: `Bot ${TelegramsNameIntegration}`,
					api_token_bot: '',
					access_token: ''
				}

				this.arrayTelegram = { ...this.company.telegrams }
			} else if (wiw == 'email') {//email
				const integrationsEmail = Object.keys(this.company.emails)
				let emailNameIntegration = this.validateIntegrationNumber(integrationsEmail)

				this.company.emails[`integration-${emailNameIntegration}`] = {
					label: `Email ${emailNameIntegration}`,
				}

				this.arrayEmail = { ...this.company.emails }
			}
		},

		deleteIntegration(wiw, integ) {
			if (wiw == 'whatsapp') {//whatsapp
				delete this.company.whatsapps[`${integ}`]
				delete this.arrayWhatsapp[`${integ}`]
				this.arrayWhatsapp = { ...this.company.whatsapps }
			}
			else if (wiw == 'instagram') {//instagram
				delete this.company.instagrams[integ]
				delete this.arrayInstagram[integ]
				this.arrayInstagram = { ...this.company.instagrams }
			}
			else if (wiw == 'widget') {//widget
				delete this.company.widgets[integ]
				delete this.arrayWidget[integ]
				this.arrayWidget = { ...this.company.widgets }
			}
			else if (wiw == 'whatsapp-official') {//whatsapps oficiais
				delete this.company.official_whatsapps[integ]
				delete this.arrayWhatsappOfficial[integ]
				this.arrayWhatsappOfficial = { ...this.company.official_whatsapps }
			}
			else if (wiw == 'telegram') {//telegrams
				delete this.company.telegrams[integ]
				delete this.arrayTelegram[integ]
				this.arrayTelegram = { ...this.company.telegrams }
			}
			else if (wiw == 'email') {//email
				this.integrationInfo.token = this.company.emails[integ].access_token
				this.integrationInfo.integ = integ
				$('#deleteModal').modal('show')
				this.arrayEmail = { ...this.company.emails }
			}
		},

		async confirmDeleteIntegration() {
			const companyModified = this.modifyAttributesCompany(this.company)

			this.aceptIntegrations()

			if (this.integrationInfo.token) {
				axios.delete(`${process.env.VUE_APP_URL_TALLOS_SSO}/auth/token/email/revoke`, {
					headers: {
						"Authorization": "Bearer " + this.integrationInfo.token
					}
				})
			}
			delete this.company.emails[this.integrationInfo.integ]
			delete this.arrayEmail[this.integrationInfo.integ]

			await this.$tallos_admin_api
				.put(`companies/${this.$route.params.id}`,
					{
						user: this.$store.getters.admin,
						...companyModified
					})
				.then(res => {
					this.$toasted.global.defaultSuccess({ msg: 'Os dados foram atualizados com sucesso.' })
				})
				.catch(e => {
					this.$toasted.global.defaultError({ msg: 'Erro ao atualizar.' })
				})
		},

		aceptIntegrations() {
			if (Object.keys(this.company.instagrams).length == 0) {
				delete this.company.instagrams
			} else {
				this.company.instagrams = { ...this.arrayInstagram }
			}
			if (Object.keys(this.company.whatsapps).length == 0) {
				delete this.company.whatsapps
			} else {
				this.company.whatsapps = { ...this.arrayWhatsapp }
			}

			if (Object.keys(this.company.widgets).length == 0) {
				delete this.company.widgets
			} else {
				this.company.widgets = { ...this.arrayWidget }
			}

			if (Object.keys(this.company.official_whatsapps).length == 0) {
				delete this.company.official_whatsapps
			} else {
				this.company.official_whatsapps = { ...this.arrayWhatsappOfficial }
			}

			if (Object.keys(this.company.telegrams).length == 0) {
				delete this.company.telegrams
			} else {
				this.company.telegrams = { ...this.arrayTelegram }
			}

			if (Object.keys(this.company.emails).length == 0) {
				delete this.company.emails
			} else {
				this.company.emails = { ...this.arrayEmail }
			}

		},

		migrationContacts() {
			if (!this.contactsIntegrantios) {
				this.$toasted.global.defaultError({ msg: 'Selecione integração.' })
				return false
			}

			this.$tallos_admin_api.defaults.headers.common["Authorization"] =
				`Bearer ${this.$store.getters.tokenJWT}`;

			this.$tallos_admin_api
				.put(`customers`, {
					integration: this.contactsIntegrantios,
					company_id: this.$route.params.id
				})
				.then(res => {
					this.$toasted.global.defaultSuccess({ msg: 'Contatos migrados com sucesso.' })
				})
				.catch(e => {
					this.$toasted.global.defaultError({ msg: 'Erro ao atualizar.' })
				})

		},

		activeBtn() {
			return this.company.account_type
		},

		/**
		 * @param {String} companyId
		 */
		async handleSubmitForwardPhrase(companyId) {
			this.modalForwardPhraseIsLoading = true;
			$('#modalSubmitForwardPhrase').modal('show')
			try {
				this.forwardPhraseDocument = (await this.$tallos_admin_api.get(`companies/${companyId}/forwardPhrase`))?.data
				this.modalForwardPhraseIsLoading = false;
			} catch (error) {
				setTimeout(() => {
					$('#modalSubmitForwardPhrase').modal('hide')
					this.$toasted.global.defaultError({ msg: 'Erro ao localizar frases de encaminhamento.' })
					this.forwardPhraseDocument = {
						phrases: ''
					}
				}, 1000)
			}
		},

		async handleUpdateForwardPhrases() {
			try {
				await this.$tallos_admin_api.put(`companies/${this.company._id}/forwardPhrase`, {
					phrases: this.forwardPhraseDocument.phrases
				})
				this.$toasted.global.defaultSuccess({ msg: 'frases de encaminhamento atualizadas com sucesso' })
			} catch (error) {
				this.$toasted.global.defaultError({ msg: 'Erro ao atualizar frases de encaminhamento.' })
			}
			this.forwardPhraseDocument = {
				phrases: ''
			}
		}
	},

	created() {
		this.getCompany();
		document.title = 'Tallos Admin - Editar Empresa'
	},

	mounted() {
		this.$tallos_admin_api.defaults.headers.common["Authorization"] = `Bearer ${this.$store.getters.tokenJWT}`;
		setInterval(() => {
			if (this.company.whatsapps) {
				this.arrayWhatsapp = { ...this.company.whatsapps }
			} else {
				this.company.whatsapps = {}
				this.arrayWhatsapp = { ...this.company.whatsapps }
			}

			if (this.company.instagrams) {
				this.arrayInstagram = { ...this.company.instagrams }
			} else {
				this.company.instagrams = {}
				this.arrayInstagram = { ...this.company.instagrams }
			}

			if (this.company.widgets) {
				this.arrayWidget = { ...this.company.widgets }
			} else {
				this.company.widgets = {}
				this.arrayWidget = { ...this.company.widgets }
			}

			if (this.company.official_whatsapps) {
				this.arrayWhatsappOfficial = { ...this.company.official_whatsapps }
			} else {
				this.company.official_whatsapps = {}
				this.arrayWhatsappOfficial = { ...this.company.official_whatsapps }
			}

			if (this.company.telegrams) {
				this.arrayTelegram = { ...this.company.telegrams }
			} else {
				this.company.telegrams = {}
				this.arrayTelegram = { ...this.company.telegrams }
			}

			if (this.company.emails) {
				this.arrayEmail = { ...this.company.emails }
			} else {
				this.company.emails = {}
				this.arrayEmail = { ...this.company.emails }
			}


			$(function () {
				$('[data-toggle="tooltip"]').tooltip()
			})

		}, 500)

	}

};


</script>

<style scoped>
.box-config {
	width: 100%;
	max-width: 1024px;
	margin: 0 auto;
	padding: 0.5rem;

	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
}

.add-due-date {
	display: inline-block;
	border-top: 1px solid #0002;
	border-bottom: 1px solid #0002;
	/* width:100px; */
	padding: 0px 15px;
	text-align: center;
	font-size: 23px;
	font-weight: 800;
	color: #696969;
}

#account_limit_days,
#max_included_users {
	text-align: center;
	background-color: #ffffff;
	font-size: 15px;
}

.div-hashs {
	position: fixed;
	background-color: #fff9;
	bottom: 10px;
	width: 60px;
	border: 1px solid #0002;
	border-radius: 4px;
	z-index: 10;
}

.content-wrapper {
	background-color: #fff;
}

.div-flex {
	display: flex;
	/* flex-direction: column; */
	justify-content: center;
	align-items: center;
	/* width: 900px; */
}

.div-init {
	width: 300px;
}

.text-small {
	color: #0008;
	font-size: 13px;
	font-weight: 400;
	line-height: 1.471;
}

.li-info {
	color: #737373;
	font-size: 14px;
}

code {
	padding: 2px 4px;
	font-size: 78%;
	color: #c7254e;
	background-color: #f9f2f4;
	border-radius: 4px;
}

ul {
	text-decoration: none;
}

.btn-big {
	width: 180px;
	padding: 8px;
}

.div-switch-trial {
	padding: 0px;
	display: flex;
	justify-content: flex-start;
	width: 300px;
	background-color: #0001;
	border-radius: 5px;
}

.div-switch-premium {
	padding: 0px;
	display: flex;
	justify-content: flex-end;
	width: 250px;
	background-color: rgba(238, 238, 238, 0.952);
	border-radius: 5px;
}

.btn-group-account-type {
	display: flex;
	align-items: center;
}

.badge {
	margin-left: 13px;
	padding: 5px 10px;
	width: 100px;
}

.btn-account-type {
	width: 25%;
}

#select-plans {
	width: 180px !important;
}

table {
	background-color: transparent;
}

.word-custom {
	color: #666666;
	font-size: 15.4px;
}

.word-custom {
	cursor: default;
}

.word-custom-small {
	color: #666666;
	font-size: 13.5px;
	font-weight: 600;
}

.word-custom-radio {
	color: #666666;
	/* font-size: 13.5px; */
	font-weight: 400;
}

input[type="radio"],
label,
.label-permission,
.label-permission b,
[type="checkbox"] {
	cursor: pointer;
}

.div-whatsapp {
	background-color: rgb(128, 241, 176, 0.1);
}

.div-instagram {
	background-color: rgba(230, 68, 122, 0.1);
}

.div-widget {
	background-color: rgba(47, 89, 228, 0.1);
}

.div-whatsapp-official {
	background-color: rgba(3, 194, 83, 0.1);
}

.div-telegram {
	background-color: rgba(0, 68, 255, 0.1);
}

.div-elements-integrations {
	max-height: 100px;
	overflow-x: hidden
}

.background-clock {
	background-color: #fff;
	cursor: pointer
}

.bg-voip {
	background: #9c27b0;
	color: #FFF;
}

.boxDueDate {
	width: 350px !important;
}

.div-email {
	background-color: rgba(255, 217, 0, 0.1);
}


@media only screen and (min-width: 800px) {
	.btn-account {
		display: flex;
		justify-content: center;
		width: 50%;
		height: 100%;
		font-size: 90%;
		letter-spacing: 0px;
	}
}

@media only screen and (min-width: 1200px) {
	.btn-account {
		width: 50%;
		height: 100%;
		font-size: 100%;
		/* letter-spacing: -1px; */
	}
}

.input-max-included-users {
	height: 37px;
	border-radius: 0px !important;
	min-width: 80px;
	width: 100px;
	text-align: center;
	font-size: 25px;
	font-weight: 800;
}

.textarea-forward-phrase {
	width: 100%;
	resize: none;
	height: 200px;
}
</style>
